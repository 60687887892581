// 
// Global: footer
// 

.footer {
    background-color: $gray;
    position: relative;
    z-index: 2;
    .footer-copyright{
        padding: 25px 0;
        border-bottom: 1px solid $gray-200;
    }
    
    .copyright-row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        p{
            margin: 0;
        }
        .comup{
            color: rgba($black, 0.5);
            margin-left: 6px;
            &:hover{
                color: $secoundary;
            }
        }
        .copyright-brand{
            margin-right: 35px;
            padding-bottom: 8px;
            img{
                max-width: 70px;
            }
        }
    }
    .copyright{
        display: flex;
        align-items: center;
        margin-right: auto;
    }
    .footer-data{
        display: flex;
        align-items: center;
        p + p{
            margin-left: 18px;
        }
    }

    .footer-menu-row{
        display: flex;
        padding: 40px 0;
        justify-content: space-between;
    }

    .menu-item-group{
        display: flex;
    }

    ul.footer-socials{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        > li a{
            font-size: 16px;
            color: $black;
            display: block;
            padding: 2px 6px;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .footer-menu-item{
        display: flex;
        .menu-item-title{
            text-transform: uppercase;
            color: $black;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 700;
            padding-right: 40px;
            border-right: 1px solid $gray-200;
        }
        ul.menu-item-list{
            list-style: none;
            display: block;
            margin: 0;
            margin-left: 40px;
            padding: 0;
            > li{
                margin-bottom: 8px;
            }
            > li > a{
                color: rgba($black, 0.6);
                &:hover{
                    color: $secoundary;
                }
            }
        }
    }
}


@include breakpoint(medium down) {
    .footer{
        .footer-menu-row{
            display: block;
            padding-top: 20px;
        }
        .footer-menu-item{
            display: block;
            margin-bottom: 25px;
            ul.menu-item-list{
                margin: 10px 20px 10px 0px;
            }
        }
    }
}

@include breakpoint(small down) {
    .footer{
        
        .copyright-row {
            .copyright-brand{
                img{
                    max-width: 45px;
                }
            }
        }
        .footer-data{
            display: block;
            p + p{
                margin: 5px 0;
            }
        }
        .footer-menu-row{
            display: block;
            margin: 5px 0;
            padding-bottom: 10px;
            .footer-menu-item{
                margin-bottom: 25px;
            }
        }
        .footer-menu-item{
            display: block;
            ul.menu-item-list {
                margin-left: 0;
                margin-bottom: 10px;
            }

        }
        .menu-item-group{
            display: block;
        }
    }
}
