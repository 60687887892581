// 
// layout: offcanvas
// 
.offcanvas-container {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $primary;
    transform: translate3d(-100%, 0%, 0);
    z-index: 99;
    width: 100%;
    transition: all .4s;
    overflow-y: auto;
    .offcanvas-inner{
        padding: 100px 20px 20px 20px;
    }

    ul.offcanvas-menu{
        list-style: none;
        padding: 0;
        margin: 0;
        ul{
            list-style: none;
            padding: 0px 0px 0px 30px;
            margin: 0; 
        }

        ul ul {
            display: none;
        }
        
        li  a{
            color: #6c6c6c;
            font-size: 18px;
            font-weight: 600;
            display: block;
            padding: 18px 0px;
            border-bottom: 1px solid #322f2f;
            transition: all 0.3s;
        }

    }

    .gt-switch{
        margin: 20px 0;
        .button{
            display: block;
            width: 100%;
        }
    }
}

#offcanvas.active{
    transform: translate3d(0, 0, 0);
}





