// 
// component: page-hero
// 

.page-hero-placeholder{
    background-color: $black;
    height: 160px;
}

.page-hero{
    position: relative;
    padding-bottom: 42%;
    overflow: hidden;
    background-color: $black;
    .page-hero-background, .page-hero-mask, .page-hero-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .page-hero-background{
        background-size: cover;
        background-position: 50%;
        z-index: 1;
    }
    .page-hero-mask{
        background-color: rgba($black, 0.6);
        z-index: 2;
    }

    .page-hero-content{
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .h1{
        color: $white;
    }
    .h4{
        color: $secoundary;
    }
}


@include breakpoint(large down) {
    .page-hero{
        padding-bottom: 42%;
    }
    .page-hero-placeholder{
        height: 90px;
    }
}


@include breakpoint(medium down) {
    .page-hero{
        padding-bottom: 50%;
        .page-title{
           margin: 0;
        }
    }
}

@include breakpoint(small down) {
    .page-hero{
        padding-bottom: 70%;
        .page-hero-content{
            padding-top: 90px;
        }
    }
}