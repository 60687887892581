// 
// Page: Product single
// 

.section-gray {
    padding: 60px 0;
    margin: 60px 0;
    position: relative;
    z-index: 1;
    .grid-container{
        position: relative;
    }
    .section-gray-bg{
        position: relative;
        position: absolute;
        left: -10%;
        right: -10%;
        top: -60px;
        bottom: -60px;
        background-color: $gray;
        z-index: -1;
    }
}

.product-image-swiper{
    margin-top: 20px;
    border:1px solid $gray-200;
    padding: 10%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 520px;

    .swiper-slide{
        text-align: center;
    }
}

ul.product-image-thumb{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin: 10px -10px;
    > li{
        flex: 0 0 25%;
        padding: 10px;
    }
    .image-holder{
        border:1px solid $gray-200;
        cursor: pointer;
        .image-holder-bg{
            left: 20px;
            right: 20px;
            top: 20px;
            bottom: 20px;
        }
    }
}

.product-single{
    padding: 20px;
}
.product-subtitle{
    position: relative;
    padding: 15px 0;
    margin: 35px 0 30px 0px;
    font-weight: 600;
    font-size: 18px;
    &:after{
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: 0;
        height: 1px;
        background-color: $gray-200;
    }
}

.product-single-description{
    th, td {
        padding: 8px;
    }
    td span {
        font-weight: bold;
    }
    
    td {
        border: 1px solid $gray-200;
    }
}

.product-single-buy {
    width: 280px;
    max-width: 100%;
    margin: 10px auto;
}

@include breakpoint(xlarge down) {
    .product-single-description{
        th, td {
            padding: 6px;
        }
    }
}


@include breakpoint(medium down) {
    .product-single{
        padding: 20px 0;
    }
    .product-subtitle{
        padding: 10px 0;
        margin: 10px 0;
        border-bottom: 1px solid $gray-200;
        &:after{
            content: none;
        }
    }

    .product-image-swiper{
        min-height: auto;
    }
}