
.plyr__control.plyr__tab-focus{
    box-shadow: none;
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded='true']{
    background-color: transparent;
}

.plyr__control--overlaid{
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none; svg{
        display: none;
    }
    &:after{
        content: "\e921";
        font-family: 'icomoon' !important;
        color: $white;
        font-size: 90px;
    }
}



