.button{
    text-transform: uppercase;
    font-size: 14px;
    padding: 14px 30px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s;
    outline: none;
    [class^="icon"]{
        font-size: 20px;
        margin-right: 8px;
    }
}


.button.primary{
    background-color: transparent;
    border:1px solid $gray-200;
    color: $dark;
    &:hover, &:focus{
        background-color: $secoundary;
        border-color: $secoundary;
    }
}

.button.white{
    background-color: transparent;
    border:1px solid rgba($white, 0.5);
    color: $white;
    &:hover, &:focus{
        background-color: $secoundary;
        border-color: $secoundary;
    }
}

.button.dark{
    background-color: $black;
    border:1px solid $black;
    color: $white;
    &:hover, &:focus{
        background-color: $secoundary;
        border-color: $secoundary;
        color: $white;
    }
}

.button.dark-outline{
    background-color: transparent;
    border:1px solid $black;
    color: $black;
    &:hover, &:focus{
        background-color: $black;
        border-color: $black;
        color: $white;
    }
}

.button.secoundary{
    background-color: $secoundary;
    border:1px solid  $secoundary;
    color: $white;
    &:hover, &:focus{
        background-color: darken( $secoundary, 5%);
    }
}

.button.green{
    background-color: $green;
    &:hover, &:focus{
        background-color: darken( $green, 5%);
    }
}

.button.gray{
    background-color: $gray;
    color: rgba($dark, 0.6);
    &:hover, &:focus{
        background-color: darken( $gray, 5%);
    }
}

.button-switch{
    color: $white;
    background-color: #b80005;
    margin: 0;
    margin-right: 15px;
    &:hover, &:focus{
        background-color: darken( #b80005, 10%);
    }
}

.button.fixed{
    min-width: 125px;
    height: 40px;
    box-sizing: border-box;
}

// arow
.arrow{
    font-size: 9px;
    font-size: 14px;
    color: $black;
    padding: 18px 18px;
    outline: none;
    &:hover{
        color: $secoundary;
    }
}

.arrow-left{
    border-right: 1px solid $gray-200;
}
.arrow-right{
    border-left: 1px solid $gray-200;
}

.paginnation-buttons{
    .arrow-left{
        border:0px;
    }
}

.button-arrow{
    width: rem-calc(32);
    height: rem-calc(50);
    background-color: $gray;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25;
    outline: none;
    &:hover, &:focus{
        background-color: $secoundary;
        color: $white;
    }
}


@include breakpoint(large down) {
    .button.fixed:not(.fixed-all){
        min-width: 170px;
        height: rem-calc(40);
    }
}
