//
// component: table
//

tbody th, tbody td, thead th, thead td, tfoot th, tfoot td{
    padding: 12px 40px;
}

thead, tbody{
    background-color: $white;
    border:0px;
}

tbody tr, tbody tr:nth-child(even){
    background-color: $white;
}

tbody .title{
    background-color: $gray;
    color: $secoundary;
    font-weight: 700;
    text-transform: uppercase;
}


@include breakpoint(small down) { 
    tbody th, tbody td, thead th, thead td, tfoot th, tfoot td{
        padding: 12px 10px;
    }
}