// 
// Page: Home
// 

//product swiper
.section-products-swiper{
    padding: 70px 0;
    .section-title{
        margin-bottom: 40px;
    }
    .tabs-style-v1{
        margin: 40px -20px;
    }
    .product .product-slide-title{
        text-align: center;
    }
}

// callout
.callout-container{
    position: relative;
    height: 480px;

    .h3{
        color: $white;
    }
    p{
        color: rgba($white, 0.6);
    }
    .button{
        opacity: 0;
    }

    .callout-item{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 55%;
    }
    .ci-left{
        left: 0;
        z-index: 2;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
        padding-right: 5%;
    }
    .ci-right{
        right: 0;
        z-index: 1;
    }
    .callout-image, .callout-image-mask, .callout-content, .callout-image-mask-hover{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .callout-image{
        background-size: cover;
        background-position: 50%;
    }
    .callout-image-mask{
        background-color: rgba($black, 0.4);

    }
    .callout-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .callout-image-mask-hover{
        background-color: $secoundary;
        mix-blend-mode: overlay;
        opacity: 0;
        transition: all 0.3s;
    }

    .inner{
        transform: translateY(50px);
        transition: all 0.3s;
    }
    

    .callout-item:hover{
        .inner{
            transform: translateY(0px);
        }
        .button{
            opacity: 1;
        }
        .callout-image-mask-hover{
            opacity: 1;
        }
    }
}


// partners
.section-partners{
    padding: 70px 0;
    .section-title{
        margin-bottom: 40px;
    }
}


@include breakpoint(large down) { 
    .callout-container {
        .inner{
            transform: translateY(0px);
        }
        .button{
            opacity: 1;
        }
    }
}


@include breakpoint(medium down) { 
    .callout-container {
        height: auto;
        .callout-item{
            width: 100%;
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            bottom: auto
        }
        .callout-content{
            position: static;
            padding: 60px 10px;
        }
        .ci-left{
            clip-path: none;
            padding-right: 0;
        }
    }
}