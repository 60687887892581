// 
// page: contact
// 


.contact-data{
    padding-top: 110px;
    .contact-data-group{
        margin-bottom: 40px;
        .h3, .h4{
            margin: 0;
        }
    }
}
.form-contact-page{
    margin: 60px 0;
    background-color: $gray;
    padding: 50px 0;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray;
        left: -20%;
        right: -20%;
        z-index: -1;
    }
}


@include breakpoint(large down) { }

@include breakpoint(medium down) { }

@include breakpoint(small down) {
    .contact-data{
        padding-top: 0;
    }
    .form-contact-page{
        padding: 20px 0;
        margin: 20px 0;
    }
}