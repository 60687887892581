// 
// layout: header
// 
.header{
    position: fixed;
    left: 0px;
    right: 0;
    top: 0;
    z-index: 999;
    padding: 0px 40px 0px 30px;
    background-color: $white;
    transition: all 0.3s;
    .header-row{
        display: flex;
        align-items: center;
    }
    .header-brand{
        margin-right: 60px;
        img{
            max-width: 70px;
        }
    }
    .header-group{
        display: flex;
        align-items: center;
    }
    ul.header-menu, ul.header-socials, ul.header-languages {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
    }
    ul.header-menu{
        margin-right: auto;
        > li{
            margin: 0 10px;
        }
        > li a{
            display: block;
            font-size: 18px;
            font-weight: 700;
            padding: 40px 0;
            text-transform: uppercase;
            color: $black;
            transition: all 0.3s;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .header-toggle{
        display: none;
        button{
            outline: none;
        }
    }

    ul.header-socials{
        align-items: flex-end;
        > li a{
            font-size: 16px;
            color: $black;
            display: block;
            padding: 2px 6px;
            &:hover{
                color: $secoundary;
            }
        }
    }

    ul.header-languages{
        > li a{
            display: block;
            font-size: 18px;
            font-weight: 700;
            padding: 40px 0;
            text-transform: uppercase;
            color: $black;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .search{
        padding: 4px 40px;
        margin: 0 40px;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        a{
            color: $dark;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.header-megamenu{
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba($black, 1); 
    transform: translate3d(0%, -100%, 0);  
    transition: all 0.3s;
    .megamenu-inner{
        padding: 120px 0px 40px 155px;
        display: flex;
    } 
    
    ul.menu-megamenu {
        li, a {
            position: relative;
            z-index: 999;
        }
        .vertical.nested {
            &:before{
                content: "";
                position: absolute;
                z-index: -1;
                width: 200vw;
                left: -100vw;
                top: 0;
                bottom: 0;
                background-color: rgba($black, 1); 
            }
        }
        a{
            color: #6c6c6c;
            font-size: 18px;
            font-weight: 600;
            display: block;
            padding: 16px 0px;
            border-bottom: 1px solid #322f2f;
            transition: all 0.3s;
            &:hover {
                color: $white;
                border-color: $secoundary;
                background-color: transparent!important;
            }
        }

        .is-active > a {
            color: $white;
            border-color: $secoundary;
            background-color: transparent;
            &:hover{
                color: $white;
                border-color: $secoundary;
                background-color: transparent;
            }
        }

        .is-dropdown-submenu {
            padding-left: 12px;
            padding-right: 12px;
            background-color: transparent;
            border:0px;
        }

        &.vertical > li.opens-right > a::after,
        .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
            content: "\e930";
            font-family: 'icomoon' !important;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 9px;
            color: $white;
            border:0px;
        }
    }
}

.header-megamenu.show{
    transform: translate3d(0%, 0%, 0);  
}

.page-scroll{
    .header{
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.25);
    }
}

@include breakpoint(large down) {
    .header{
        padding: 10px;
        ul.header-menu, .header-group{
            display: none;
        }
        .header-brand{
            margin-right: auto;
            img{
                max-width: 70px;
            }
        }
        
        ul.header-languages > li a{
            padding: 0 4px;
        }
        .header-toggle{
            display: block;
            margin-left: 10px;
            margin-top: 5px;
        }
        .search{
            margin: 0 20px;
            padding: 0 20px;
        }
    }
    .header-megamenu{
        display: none;
    }
}

@include breakpoint(small down) {
    .header .header-brand img{
        max-width: 45px;
    }
}
