// 
// Page: Products
// 

.products-head{
    display: flex;
    justify-content: space-between;
    .products-filter-select{
        width: 250px;
        max-width: 100%;
    }
    .h4{
        padding-top: 10px;
        padding-left: 40px;
        margin-bottom: 0;
    }
}

.products-filter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.products-filter-label{
    margin-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.products-category{
    margin: 30px 0;
    .product-category-title{
        padding-left: 25px;
        margin-bottom: 30px;
    }
}

.product-title {
    margin-bottom: 4px;
}

.product-code {
    font-size: 18px;
    margin-bottom: 0;
    span {
        font-weight: 600;
    }
}

.product-category-row{
    padding: 40px 0;
    display: flex;
    .products-category-image{
        flex: 0 0 45%;
    }
    .h4{
        margin-bottom: 40px;
    }
}

.products-sort-pagination{
    display: flex;
    justify-content: flex-end;
    background-color: $gray;
    padding: 10px 0px 10px 25px;
    .h4{
        margin: 0px;
    }

    .sort-group{
        display: flex;
        align-items: center;
    }
}

.products-list{
    margin: 20px 0;
    .cell{
        margin-bottom: 20px;
    }
}

.products-list-title{
    padding-left: 20px;
    margin-bottom: 40px;
}

.product-list-wrapper{
    padding: 5px 10px;
    position: relative;
    &:before, &:after{
        content: "";
        position: absolute;
    }
    &:before{
        right: 0px;
        top: 5px;
        bottom: 5px;
        width: 1px;
        background-color: $gray-200;
    }
    &:after{
        left: 0px;
        right: 0px;
        bottom: -10px;
        height: 1px;
        background-color: $gray-200;
    }
}

.products-list > li:nth-child(3n) .product-list-wrapper:before{
    content: none;
}


@include breakpoint(xlarge down) {
    .product-category-row{
        .h4{
            margin-bottom: 20px;
        }
    }
}

@include breakpoint(medium down) {
    .products-head{
        display: block;
        margin-bottom: 10px;
        .h4{
            padding-left: 0;
            margin-bottom: 6px;
        }
        .products-filter-select{
            width: 100%;
        }
    }
}

@include breakpoint(small down) {
    
    .products-filter{
        display: block;
    }
    .product-category-row{
        padding: 10px 0;
        display: block;
    }

    .products-category{
        .product-category-title{
            padding-left: 0;
        }
    }

    .products-sort-pagination{
        display: block;
        .h4{
            margin-bottom: 8px;
        }
    }

    .products-list-title{
        padding-left: 0px;
        margin-bottom: 20px;
    }
}