// 
// Global: article
// 

article{
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        color: $primary;
        margin-bottom: 40px;
    }
    ul, ol{
        margin: 40px 0;
    }
}

.content-hero-text{
    padding: 60px 0;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        color: $primary;
        margin-bottom: 30px;
    }
    p{
        font-size: 18px;
        font-weight: 400;
    }
}


.grid-content{
    padding: 60px 0;
    .cell-content-text .inner{
        padding: 20px 60px;
        max-width: 620px;
        margin: 0 auto;
    }
    .cell-content-image-block{
        img{
            display: block;
            width: 100%;
        }
       
    }

    .cell-content-image-background{
        .image-holder{
            background-color: $gray;
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 40px;
    }
}

.grid-content-half-margin{
    margin: 60px 0;
}


.grid-content-container{
    margin: 0px 0;
    .cell-content-text .inner{
        padding: 20px 0px;
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 40px;
    }
    .button{
        margin-top: 40px;
    }
}

.image-bg{
    position: relative;
    padding: 10% 8%;

    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1059px;
        height: 732px;
        z-index: -1;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.image-bg-1{
    &:before{
        background-image: url(../img/bg-1.jpg);
    }
   
}
.image-bg-2{
    &:before{
        background-image: url(../img/bg-2.jpg);
    }
}

.image-bg-3{
    &:before{
        background-image: url(../img/bg-3.jpg);
    }
    .image-holder{
        mix-blend-mode: unset;
    }
}

.image-block{
    img{
        display: block;
        width: 100%;
    }
}


// text-box
.text-box{
    background-color: $gray;
    padding: 60px 0px;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        background-color: $gray;
        z-index: -1;
    }
}

.text-box-left:before{
    left: -60%;
    right: -25%;
}

.text-box-right:before{
    right: -60%;
    left: -25%;
}


@include breakpoint(xlarge down) {
    .image-bg{
        padding: 10% 15%;
        &:before{
            content: none!important;
        }
    }
}


@include breakpoint(medium down) {
    .grid-content{
        margin: 0px;
        .cell-content-text{
            order: 1;
            .inner{
                padding: 20px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
       
        .cell-content-image-block{
            order: 2
        }
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            margin-bottom: 20px;
        }
    }
}


@include breakpoint(small down) {
    .article-hero-text{
        padding: 40px 0;
        p{
            font-size: 16px;
        }
        .h4{
            margin-bottom: 20px;
        }
    }
}



