.box-item{
    position: relative;
    overflow: hidden;
    height: 320px;
    display: block;
    .box-background, .box-mask, .box-content{
        position: absolute;
        left: 0;right: 0;
        top: 0;
        bottom: 0;
    }
    .box-background{
        background-size: cover;
        background-position: 50%;
    }
    .box-mask{
        background-color: rgba($black, 0.8);
        transition: all 0.4s;
    }
    .box-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .h1, .h2, .h3, .h4, .h5, .h6{
            color: $white;
            margin: 0;
            text-transform: uppercase;
            font-weight: 500;
            text-align: center;
        }
    }
}

.box-item:hover{
    .box-mask{
        background-color: rgba($secoundary, 0.8);
    }
}