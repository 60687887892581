
.search-offcanvas{
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-left: 80px;
    background-color: $black;
    transform: translate3d(0%, 100%, 0);
    z-index: 9999;
    transition: all .4s;
    .offcanvas-close{
        position: absolute;
        right: 40px;
        top: 40px;
        font-size: 44px;
        cursor: pointer;
        z-index: 44;
        a{
            color: rgba($white, 0.4);
            &:hover{
                color: rgba($white, 0.8);
            }
        }
    }
    .offcanvas-search-container{
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        
        .seach-input{
            position: relative;
            margin-bottom: 16px;
    
            input{
                display: block;
                width: 100%;
                border:0px;
                height: auto;
                background-color: transparent;
                border-bottom: 1px solid $gray-200;
                font-weight: 300;
                font-family: inherit;
                font-size: 80px;
                color: $white;
                padding: 18px 15px;
                outline: none;
                border-radius: 0px;
                &:focus{
                    outline: none;
                    box-shadow: none;
                    border-bottom: 1px solid $gray-200;
                }
            }
    
            button{
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: transparent;
                border:0px;
                outline: 0px;
                color: $white;
                font-size: 24px;
                cursor: pointer;
            }
        }
    
        .search-help{
            text-align: right;
            color: $white;
        }
    
    }
}

.search-offcanvas.active{
    transform: translate3d(0%, 0%, 0);
}