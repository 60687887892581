.splash{
    background-color: $black;
    height: 100vh;
    background-image: url(../img/splash.jpg);
    background-size: cover;
    position: relative;
    overflow: hidden;
    .sp-left, .sp-right{
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after{
            content: "";
            position: absolute;
            transform: rotate(15deg);
            transition: all 0.3s;
            z-index: 1;
        }
    }
    
    .sp-left{
        left: 0;
        width: 50%;
        &:after{
            right: 5%;
            top: -50%;
            width: 200%;
            height: 200%;
            background-color: #1e0202;
            opacity: 0.6;
            transition: all 0.25s;
        }

        .button:hover{
            background-color: #b80005;
            border-color: #b80005;
        }
    }
    .sp-right{
        right: 0;
        width: 50%;
        &:after{
            left: 2%;
            top: -50%;
            width: 200%;
            height: 200%;
            background-color: #031a30;
            opacity: 0.6;
            transition: all 0.25s;
        }

        .button:hover{
            background-color: #1392da;
            border-color: #1392da;
        }
    }

    .inner{
        transform: translateY(100px);
        position: relative;
        z-index: 2;
        text-align: center;
        box-sizing: border-box;
        transition: all 0.3s;
        p{
            color: rgba($white, 0.6);
            font-size: 16px;
            font-weight: 600;
            width: 410px;
            max-width: 90%;
            margin: 30px auto;
        }
    }

    .sp-left:hover, .sp-right:hover{
        .inner{
            transform: translateY(0px);
        }
        &:after{
            //mix-blend-mode: hue;
            opacity: 0.1;
        }
    }
}


@include breakpoint(large down) {
    .splash .inner{
        transform: translateY(0px);
    }
}


@include breakpoint(medium down) {
    .splash{
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .sp-left, .sp-right{
            position: relative;
            width: 100%;
            padding: 10%;
            min-height: 50vh;
            &:after{
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transform: none;
                width: 100%;
                height: 100%;
            }
        }
        .inner{
            transform: translateY(0px);
            p{
                width: 100%;
            }
        }
    }
}