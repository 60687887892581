//
// component: swiper
//

.swiper-slide > .image{
    width: 100%;
}

.swiper-pagination{
    position: static;
    margin-top: 1rem;
}

.swiper-button-prev, .swiper-button-next{
    width: auto;
    height: auto;
    background-image: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: $black;
    padding: 20px 18px;
    outline: none;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
    opacity: 0;
}

.swiper-button-prev{
    border-right: 1px solid $gray-200;
}
.swiper-button-next{
    border-left: 1px solid $gray-200;
}


// swiper products
.swiper-products-container{
    position: relative;
    .swiper-button-prev{
        left: -80px;
        transform: translateY(-50%);
    }
    .swiper-button-next{
        right: -80px;
        transform: translateY(-50%);
    }

    .product{
        margin: 10px;
    }
}

.swiper-product{
    h1, .h1, .h2, h3, .h3,h4, .h4, h5, .h5, h6, .h6{
        color: $white;
        margin-bottom: 55px;
    }
    p{
        color: rgba($white, 0.6);
        font-weight: 500;
    }

    .image-product{
        padding: 40px 20%;
    }
}


// swiper single
.swiper-single-container{
    position: relative;
    z-index: 4;
    background-color: $black;
    .swiper-container{
        position: relative;
        z-index: 1;
    }
    .grid-container{
        box-sizing: border-box;
    }
    .swiper-slide{
        position: relative;
        padding-bottom: 42.5%;
    }
    .swiper-slide-bg, .swiper-slide-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .swiper-slide-bg{
        background-size: cover;
        background-position: top 50%;
        z-index: 10;
        left: -10%!important;
        right: -10%!important;
        bottom: -10%!important;
        top: -10%!important;
        position: absolute!important;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($black, 0.3);
        }
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(to right, black 0%, rgba($black, 0.9) 12%,transparent 100%);
        }
    }
    .swiper-slide-content{
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transform: translateY(20px);
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.45s;
    }
    
    .h1{
        color: $white;
        margin: 0;
    }
    
    .h4{
        color: $secoundary;
        margin-bottom: 18px;
    }
    p{
        color: rgba(255, 255, 255, 0.6);
        width: 360px;
        max-width: 100%;
        font-weight: 500;
    }
    
    .swiper-single-pagination{
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 20px;
        z-index: 44;
    }
    
    .swiper-pagination{
        margin: 0;
        display: flex;
        justify-content: center;
    }
    
    .swiper-pagination-bullet{
        width: auto;
        height: auto;
        opacity: 1;
        background-color: transparent;
        color: rgba($white, 0.5);
        font-size: 24px;
        font-weight: 500;
        border-radius: 0px;
        margin: 0 8px;
        outline: none;
        display: block;
        padding-top: 6px;
        border-top: 1px solid transparent;
    }

    .swiper-pagination-bullet-active{
        color: $secoundary;
        border-top: 1px solid $secoundary;
    }
}

.swiper-single-container .swiper-slide-active {
    .swiper-slide-content {
        opacity: 1;
        transform: translateY(0px);
    }
}

.swiper-single-container.swiper-product{
    margin-top: 40px;

    .grid-container {
        width: 100%;
        box-sizing: border-box;
    }
    
    .image-bg{
        padding: 20%;
    }
}

.swiper-partners-container{
    position: relative;

    .swiper-button-prev{
        left: -80px;
        transform: translateY(-50%);
    }
    .swiper-button-next{
        right: -80px;
        transform: translateY(-50%);
    }
}

.swiper-single-container.home-swiper{
    .swiper-slide{
        padding-bottom: 0;
        height: 100vh;
    }
}





@include breakpoint(xlarge down) { }
@include breakpoint(large down) {
    // swiper products
    .swiper-products-container{
        padding-bottom: 60px;
        .swiper-button-prev{
            left: 10px;
            top: auto;
            transform: translateY(0%);
        }
        .swiper-button-next{
            right: 10px;
            top: auto;
            transform: translateY(0%);
        }
    }
    .swiper-single-container{
        .swiper-slide{
            padding-bottom: 60%;
        }
        .swiper-slide-bg{
            left: 0!important;
            right: 0!important;
            top: 0!important;
            bottom: 0!important;
            transform: none!important;
        }
    }

    .swiper-partners-container{
        .swiper-button-prev{
            left: 10px;
            top: auto;
            transform: translateY(0%);
        }
        .swiper-button-next{
            right: 10px;
            top: auto;
            transform: translateY(0%);
        }
    }
}
@include breakpoint(medium down) {
    .swiper-single-container.swiper-product{
        .swiper-slide{
            padding-bottom: 0;
        }
       
        .swiper-slide-content{
            position: relative;
            padding-bottom: 60px;
            p{
                width: 100%;
            }
            .h3{
                margin-bottom: 10px;
            }
        }
    }
}
@include breakpoint(small down) {
    .swiper-single-container{
        .swiper-slide{
            position: relative;
            padding-bottom: 160%;
        }
    }
    .swiper-single-container.home-swiper{
        .swiper-slide{
            padding-bottom: 160%;
            height: auto;
        }
    }
}
