// 
// Page: about
// 

.about-container-1{
    padding: 60px 0;
    .h4{
        text-align: center;
        margin-bottom: 30px;
    }
}

.about-container-2{
    position: relative;
    background-color: $primary;
    padding: 60px 0px;
    &:after{
        content: "";
        position: absolute;
        top: -20%;
        bottom: -20%;
        left: -50vw;
        right: -50vw;
        background-color: $primary;
        z-index: -1;
    }
    
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        color: $white;
        margin-bottom: 60px;
    }
    p{
        margin-bottom: 20px;
        color: rgba(255, 255, 255, 0.6);
    }
}


.about-container-3{
    position: relative;
    background-color: $gray;
    padding: 0px 0px 100px 0px;
    &:after{
        content: "";
        position: absolute;
        top: -50%;
        bottom: 0%;
        left: -50vw;
        right: -50vw;
        background-color: $gray;
        z-index: -1;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 40px;
    }
}

.grid-content-padding-top{
    margin-top: 0;
    .cell-content-text .inner{
        padding-top: 20%;
    }
}

@include breakpoint(xlarge down) {
    .about-container-2{
        padding: 50px 0px;
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            margin-bottom: 30px;
        }
        p{
            margin-bottom:15px;
        }
    }
}


@include breakpoint(medium down) {
    .about-container-1{
        padding-bottom: 20px;
    }
    .about-container-2:after{
        bottom: 0;
    }

    .about-container-3{
        padding: 40px 0;
    }

    .grid-content-padding-top .cell-content-text .inner{
        padding-top: 20px;
    }
}