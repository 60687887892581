@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?8jk0pa');
    src: url('../fonts/icomoon.eot?8jk0pa#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?8jk0pa') format('truetype'), url('../fonts/icomoon.woff?8jk0pa') format('woff'), url('../fonts/icomoon.svg?8jk0pa#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-file-empty:before {
    content: "\e924";
}

.icon-files-empty:before {
    content: "\e925";
}

.icon-file-text2:before {
    content: "\e926";
}

.icon-file-picture:before {
    content: "\e927";
}

.icon-file-music:before {
    content: "\e928";
}

.icon-file-play:before {
    content: "\e929";
}

.icon-file-video:before {
    content: "\e92a";
}

.icon-file-zip:before {
    content: "\e92b";
}

.icon-copy:before {
    content: "\e92c";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-plus1:before {
    content: "\ea0a";
}

.icon-minus1:before {
    content: "\ea0b";
}

.icon-google3:before {
    content: "\ea8a";
}

.icon-google-plus:before {
    content: "\ea8b";
}

.icon-google-plus2:before {
    content: "\ea8c";
}

.icon-google-plus3:before {
    content: "\ea8d";
}

.icon-hangouts:before {
    content: "\ea8e";
}

.icon-google-drive:before {
    content: "\ea8f";
}

.icon-facebook1:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram:before {
    content: "\ea92";
}

.icon-whatsapp:before {
    content: "\ea93";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-rss2:before {
    content: "\ea9c";
}

.icon-youtube1:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-blogger:before {
    content: "\eab7";
}

.icon-blogger2:before {
    content: "\eab8";
}

.icon-tumblr:before {
    content: "\eab9";
}

.icon-tumblr2:before {
    content: "\eaba";
}

.icon-yahoo:before {
    content: "\eabb";
}

.icon-linkedin1:before {
    content: "\eac9";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-lastfm:before {
    content: "\eacb";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-file-pdf:before {
    content: "\eadf";
}

.icon-file-openoffice:before {
    content: "\eae0";
}

.icon-file-word:before {
    content: "\eae1";
}

.icon-file-excel:before {
    content: "\eae2";
}

.icon-libreoffice:before {
    content: "\eae3";
}

.icon-appointment:before {
    content: "\e900";
}

.icon-arrow-down:before {
    content: "\e901";
}

.icon-arrow-left:before {
    content: "\e902";
}

.icon-arrow-right:before {
    content: "\e903";
}

.icon-arrow-sm-down:before {
    content: "\e904";
}

.icon-arrow-sm-left:before {
    content: "\e905";
}

.icon-arrow-sm-right:before {
    content: "\e906";
}

.icon-arrow-sm-up:before {
    content: "\e907";
}

.icon-arrow-up:before {
    content: "\e908";
}

.icon-branding:before {
    content: "\e909";
}

.icon-burger:before {
    content: "\e90a";
}

.icon-certificate:before {
    content: "\e90b";
}

.icon-chair:before {
    content: "\e90c";
}

.icon-clock:before {
    content: "\e90d";
}

.icon-close-st:before {
    content: "\e90e";
}

.icon-close:before {
    content: "\e90f";
}

.icon-cross-out:before {
    content: "\e910";
}

.icon-down:before {
    content: "\e911";
}

.icon-download:before {
    content: "\e912";
}

.icon-elevator:before {
    content: "\e913";
}

.icon-envelope:before {
    content: "\e914";
}

.icon-facebook:before {
    content: "\e915";
}

.icon-gate:before {
    content: "\e916";
}

.icon-gears:before {
    content: "\e917";
}

.icon-house:before {
    content: "\e918";
}

.icon-left:before {
    content: "\e919";
}

.icon-linkedin:before {
    content: "\e91a";
}

.icon-location:before {
    content: "\e91b";
}

.icon-magnifying-glass:before {
    content: "\e91c";
}

.icon-manager:before {
    content: "\e91d";
}

.icon-minus:before {
    content: "\e91e";
}

.icon-negotiation:before {
    content: "\e91f";
}

.icon-outsourcing:before {
    content: "\e920";
}

.icon-play-button:before {
    content: "\e921";
}

.icon-plus:before {
    content: "\e922";
}

.icon-production:before {
    content: "\e923";
}

.icon-project-management:before {
    content: "\e92d";
}

.icon-resize:before {
    content: "\e92e";
}

.icon-resume:before {
    content: "\e92f";
}

.icon-right:before {
    content: "\e930";
}

.icon-search:before {
    content: "\e931";
}

.icon-searcher:before {
    content: "\e932";
}

.icon-solution:before {
    content: "\e933";
}

.icon-teamwork:before {
    content: "\e934";
}

.icon-telephone:before {
    content: "\e935";
}

.icon-tick:before {
    content: "\e936";
}

.icon-tr-down:before {
    content: "\e937";
}

.icon-tr-left:before {
    content: "\e938";
}

.icon-tr-right:before {
    content: "\e939";
}

.icon-tr-up:before {
    content: "\e93a";
}

.icon-up:before {
    content: "\e93b";
}

.icon-usb-cable:before {
    content: "\e93c";
}

.icon-wacom:before {
    content: "\e93d";
}

.icon-worldwide:before {
    content: "\e93e";
}

.icon-youtube:before {
    content: "\e93f";
}

.icon-zoom:before {
    content: "\e940";
}