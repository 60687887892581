// 
// component: List download
// 

ul.list-download{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
    > li{
        flex: 0 0 50%;
        padding: 10px;
    }
    p{
        margin: 0;
    }

    .download-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-200;
        padding: 8px 20px;
    }
    .download-name{
        display: flex;
        align-items: center;
        [class^="icon"], img{
            margin-right: 12px;
            font-size: 20px;
        }
    }
    .download-link{
        text-transform: uppercase;
        font-size: 14px;
        color: $dark;
        font-weight: 600;
        display: flex;
        align-items: center;
        [class^="icon"], img{
            margin-left: 12px;
            font-size: 16px;
        }
    }

    .download-link:hover{
        color: $secoundary;
    }
}


@include breakpoint(medium down) {
    ul.list-download{
        > li{
            flex: 1 1 100%;
        }
    }
}