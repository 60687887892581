// 
// Page: Service
// 

.sv1, .sv2, .sv3{
    position: relative;
}

.sv1{
    position: relative;
    z-index: 4;
    margin-top: 60px;
    .cell-sv1-image{
        position: relative;
        z-index: 2;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 10%;
            background-color: $black;
            z-index: -1;
        }
    }

    .cell-sv1-content{
        z-index: 1;
    }

    .sv1-inner{
        width: 540px;
        max-width: 100%;
        margin: 0 auto;
        padding-top: 30px;
        .h3, .h4{
            margin-bottom: 40px;
        }
    }

    .sv1-box{
        background-color: $gray;
        padding: 60px 0px;
        position: relative;
        z-index: 4;
        &:before{
            content: "";
            position: absolute;
            left: -60%;
            right: -20%;
            top: 0;
            bottom: 0;
            background-color: $gray;
            z-index: -1;
        }
    }
}


.sv2{
    padding: 120px 0;
    background-color: $black;
    position: relative;
    z-index: 3;
    .sv2-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: -40%;
        bottom: 0;
        background-color: $black;
        background-size: cover;
        background-position: right center;
        z-index: 1;
    }

    .grid-container{
        position: relative;
        z-index: 2;
    }
    .box-icon-item{
        text-align: center;
        .box-icon{
            color: $secoundary;
            font-size: 72px;
            img{
                max-width: 62px;
            }
        }
        .box-num, .box-description{
            color: $white;
        }
        .box-num{
            font-size: 60px;
            font-weight: 700;
        }
        .box-description{
            width: 250px;
            max-width: 90%;
            margin: 10px auto;
        }
    }
    .sv2-list-small{
        display: flex;
        align-items: center;
        .h3{
            color: $white;
            padding-right: 60px;
        }

        .box-icon-item{
            .box-icon{
                color: $white;
            }
            .box-description{
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}



.sv3{
    position: relative;
    overflow: hidden;
    padding: 0 90px;
    .sv3-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 200px;
        background-color: $black;
        z-index: -1;
    }

    .sv3-title{
        position: relative;
        background-color: $gray;
        padding: 80px 0px 30px 0px;
        &:before{
            //content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -10%;
            right: -10%;
            background-color: $gray;
            z-index: -1;
        }
    }

    .box-icon-item{
        text-align: center;
        padding: 60px 0;
        .box-icon{
            font-size: 80px;
        }
        .box-title{
            margin-bottom: 25px;
        }
        .box-description{
            color: rgba($black, 0.6);
            font-weight: 500;
            width: 350px;
            max-width: 90%;
            margin: 10px auto;
        }
    }
    .sv3-box-icons-list{
        > li:nth-child(1),  > li:nth-child(2),  > li:nth-child(3){
            .box-icon-item{
                position: relative;
                &:before{
                    content: "";
                    position: absolute;
                    left: -31%;
                    right: -31%;
                    top: -400px;
                    bottom: 0;
                    background-color: $gray;
                    z-index: -1;
                }
            }
        }
    }
}

@media screen and (min-width:1921px){
    .sv2 .sv2-bg{
        left: -15vw;
        right: -15vw;
    }
    .sv3{
        overflow: initial;
        .sv3-bg{
            left: -50vw;
            right: -50vw;
        }
    }
}


@include breakpoint(xlarge down) { 
    .sv2{
        .box-icon-item{
            .box-num{
                font-size: 44px;
            }
        }
    }
}

@include breakpoint(large down) { 
    .sv1 {
        .sv1-inner{
            padding: 40px 20px;
        }
    }
}


@include breakpoint(medium down) { 
    .sv3{
        .sv3-box-icons-list{
            > li:nth-child(3),  > li:nth-child(4){
                .box-icon-item{
                    position: relative;
                    &:before{
                        content: none;
                    }
                }
            }
        }
    }
}


@include breakpoint(small down) {
    .sv1{
        margin-top: 10px;
        .sv1-inner{
            padding-top: 20px;
            .h3, .h4{
                margin-bottom: 20px;
            }
        }
    }
    .sv2{
        .sv2-list-small{
            display: block;
            .h3{
                text-align: center;
                padding-right: 0;
                margin-bottom: 30px;
            }
        }
    }
    
    .sv3{
        padding: 0;
        .box-icon-item{
            padding: 20px 0px;
        }
    }
}
