// 
// Page: Category
// 

.category-description{
    padding-left: 60px;
}
.category-product-swiper{
    padding: 40px 0;
    .h3{
        margin-bottom: 20px;
    }
}

.category-accessories{
    padding-bottom: 40px;
}

.category-tips{
    padding: 60px 0;
    margin: 40px 0;
    .grid-container{
        position: relative;
    }
  
   .tips-bg{
       position: absolute;
       left: -10%;
       right: -10%;
       top: -60px;
       bottom: -60px;
       background-color: $gray;
       z-index: -1;
    }
    .tabs{
        padding-top: 40px;
        background-color: transparent;
    }
    .tabs-title{
        margin: 0;
    }
    .tabs-title > a:hover{
        color: $secoundary;
        background-color: transparent;
    }
    .tabs-title.is-active > a{
        color: $white;
        background-color: $secoundary;
    } 

    .tabs-content{
        padding-top: 40px;
        background-color: transparent;
        border:0px;
    }
}


@include breakpoint(medium down) {
    .category-tips{
        margin: 10px 0;
        .tabs-content{
            padding-top: 0;
        }
        .tabs{
            padding-top: 0;
            margin-top: 0;
        }
    }
}

@include breakpoint(small down) {
    .category-description{
        padding-left: 0;
    }
}