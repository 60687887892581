// 
// component: product
// 

.product{
    position: relative;
    background-color: $white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.0);
    transition: all 0.25s;
    .product-image{
        position: relative;
        z-index: 2;
        overflow: hidden;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-description{
        padding: 20px;
        position: relative;
        z-index: 2;
        p, .h6{
            margin: 0;
        }
        p{
            text-align: center;
        }
    }
    
    .product-hover{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba($white, 0.8);
        opacity: 0;
        transition: all 0.25s;
        .button{
            margin: 3px 1px;
        }
    }
}

.product:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    .product-hover{
        z-index: 3;
        opacity: 1;
    }
}


@include breakpoint(large down) {
    .product{
        border:1px solid $gray-200;
        .product-hover{
            display: block;
            padding: 10px;
            opacity: 1;
            position: static;
            .button{
                width: 100%;
            }
        }
    }
}
