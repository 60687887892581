// 
// component: partners list
// 

ul.partners-list{
    list-style: none;
    
}
ul.partners-list> li{
    margin-bottom: rem-calc(30);
}
ul.partners-list .image-holder{
    border:1px solid $gray-100;
    padding-bottom: 50%;
    .image-holder-img{
        padding: 20% 30%;
    }
}

.partner-image{
    position: relative;
    padding-bottom: 50%;
    overflow: hidden;
    .partner-image-holder{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 20% 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

