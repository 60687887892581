
$black:        #000;
$white:        #fff;
$dark:         #38383d;
$gray:         #f7f7f8;
$gray-100:     #e0e0e0;
$gray-200:     #d9d9da;
$gray-300:     #d4d4d4;

$primary:      #16161d;
$primary-100:  #121217;
$secoundary:   #1392da;
$danger:       #dc3545;
$green:        #78b33e;
$yellow:       #f2b60f;
$menu-link:    #c0afcd;







