// 
// component: tabs
// 

.tabs-style-v1{
    border-bottom: 1px solid $gray-200;
    margin: 40px 0;
    .tabs-title{
        margin: 0 20px;
    }

    .tabs-title > a{
        color: $dark;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        padding: 14px 0px;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background-color: $secoundary;
            opacity: 0;
        }
    }

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
        color: $secoundary;
        background-color: transparent;
        &:after{
            opacity: 1;
        }
    }
}

.tabs-style-v2{
    border-bottom: 1px solid $gray-200;
    margin: 40px 0;
    .tabs-title{
        margin: 0px;
    }

    .tabs-title > a{
        color: $dark;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        padding: 18px 26px;
    }

    .tabs-title > a:hover{
        color: $secoundary;
    }

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
        color: $white;
        background-color: $secoundary;
    }
}

.tabs-style-v3{
    border: 0px;
    margin: 40px 0;
    .tabs-title{
        margin: 0 20px;
    }

    .tabs-title > a{
        color: $dark;
        font-size: 18px;
        font-weight: 500;
        position: relative;
        padding: 14px 20px;
        border-bottom: 1px solid $gray-200;
    }

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
        color: $white;
        background-color: $secoundary;
    }
}