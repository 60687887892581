.gt-grid{
    display: flex;
    flex-wrap: wrap;
}

.gt-grid-container{
    display: flex;
    .gt-grid-left{
        flex: 0 0 33.333%;
        .gt-grid-item{
            height: 860px;
            display: block;
            .gt-item-content{
                height: 430px;
                align-items: center;
                .content-image{
                    position: absolute;
                    height: 200%;
                    top: 60%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .content-body{
                    padding-left: 30%;
                }
                .content-image-bg{
                    left: -30%;
                    background-position: top center;
                }
            }
        }
    }
    .gt-grid-right{
        flex: 0 0 66.666%;
        display: flex;
        flex-wrap: wrap;
        .gt-grid-item{
            flex: 0 0 50%;
            height: 430px;
        }
    }
}

.gt-grid-item{
    position: relative;
    overflow: hidden;
    .h3{
        color: $black;
    }
    p{
        color: rgba($black, 0.6);
        width: 250px;
        max-width: 90%;
    }

    .button{
        transition: opacity 0.3s;
    }

    .gt-item-background, .gt-item-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 1;
    }

    .gt-item-background{
        background-color: #f8f8fa;
        transition: opacity 0.3s;
    }
    
    .gt-item-content{
        height: 100%;
        display: flex;
        align-items: center;
        .content-image{
            flex: 0 0 30%;
            height: 100%;
            position: relative;
            z-index: 1;
            mix-blend-mode: multiply;
        }
        .content-image-bg{
            position: absolute;
            left: -20%;
            right: 0;
            top: 5%;
            bottom: 5%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
        }
        .content-body{
            flex: 0 0 70%;
            padding: 20px 40px 20px 60px;
            box-sizing: border-box;
            position: relative;
            z-index: 2;
        }
        .inner{
            transition: all 0.3s;
        }
    }
}

.grid-item-gray{
    .gt-item-background{
        background-color: #f0f7fb;
    }
}

.grid-item-gray-2{
    .gt-item-background{
        background-color: #ecf0f3;
    }
}

.gt-grid-item-all{
    .gt-item-background{
        background-color: $secoundary;
        transition: opacity 0.3s;
    }
    .gt-item-content{
        .content-body{
            flex: 1 1 100%;
            width: 100%;
            padding: 20px 10px;
        }
        .content-image{
            position: absolute;
            left: 10%;
            right: 10%;
            top: 10%;
            bottom: 10%;
            background-size: cover;
            mix-blend-mode: unset;
            opacity: 0.1;
            height: auto;
            transition: all 0.3s;
        }
        .content-image-bg{
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
    .all-link{
        display: flex;
        align-items: center;
        justify-content: center;
        .h3{
            color: $white;
        }
        .swiper-button-next{
            position: static;
            color: $white;
            margin-left: 30px;
            border-left: 1px solid rgba($white, 0.2);
        }
    }
}


.gt-grid-item:hover{
    .h3, p{
        color: $secoundary;
    }
}

.gt-grid-item-all:hover{
    .h3, p{
        color: $white;
    }
    .content-image{
        left: 5%;
        right: 5%;
        top: 5%;
        bottom: 5%;
    }
}

@include breakpoint(large down) {
    .gt-grid-container{
        flex-wrap: wrap;
        .gt-grid-left{
            flex: 1 1 100%;
            .gt-grid-item{
                height: 430px;
                .gt-item-content{
                    height: 430px;
                    .content-image{
                        position: absolute;
                        height: 100%;
                        top: 0%;
                        left: 60%;
                        right: -20%;
                        bottom: 0;
                    }
                    .content-body{
                        padding-left: 18%;
                    }
                    .content-image-bg{
                        left: 0%;
                        background-position: top center;
                    }
                }
            }
        }
        .gt-grid-right{
            flex: 1 1 100%;
        }
    }
    .gt-grid-item{
        .button{
            opacity: 1;
        }
        .gt-item-content .inner{
            transform: translateY(0px);
        }
        .gt-item-content .content-body{
            padding: 20px 30px;
        }
    }
}


@include breakpoint(small down) {
    .gt-grid-container .gt-grid-right .gt-grid-item{
        flex: 1 1 100%;
    }

    .gt-grid-item .gt-item-content .content-image-bg{
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .gt-grid-item .gt-item-content .content-body{
        max-width: 70%;
    }

    .gt-grid-container .gt-grid-left .gt-grid-item .gt-item-content {
        .content-image{
            position: relative;
            flex: 0 0 30%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .content-body{
            padding: 20px 30px;
            
        }
        .content-image-bg{
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
        }
    }

    .grid-item-gray:nth-child(even) .gt-item-background{
        background-color: #ecf0f3;
    }

    .gt-grid-item-all{
        .gt-item-content .content-body {
            max-width: 100%;
        }
    }
}