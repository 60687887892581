// 
// Global: breadcrumbs
// 
.breadcrumbs{
    margin: 0;
    padding: 0;
    padding: 30px 0 15px 0px;
}

.breadcrumbs li{
    color: $black;
    font-weight: 600;
    font-size: 14px;
}

.breadcrumbs a{
    color: #706e6e;
}

.breadcrumbs li:not(:last-child)::after{
    content: "-";
}


@include breakpoint(small down) {
    .page-breadcrumbs{
        display: none;
    }

    .breadcrumbs li:not(:last-child)::after{
        margin: 0 0.35rem;
    }
}