//
// page: calculator
//

.calculator-form{
    padding-bottom: 40px;
    .form-section{
        padding: 20px 0;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            left: -20px;
            right: -20px;
            bottom: 0;
            height: 1px;
            background-color: $gray-200;
        }
    }

    .form-section-last{
        padding-bottom: 0;
        border:0px;
        &:after{
            content: none;
        }
    }
    .form-section-title{
        margin-bottom: 40px;
        .h3, p{
            margin: 0;
        }
    }

    .input-separator{
        height: 50px;
        display: flex;align-items: center;
        justify-content: center;
        font-size: 32px;
    }

    .from-control{
        width: 100%;
    }

    .form-group{
        margin-right: 20px;
    }

    .w1{
        width: 460px;
        max-width: 100%;
    }
    .w2{
        width: 305px;
        max-width: 100%;
    }
    .w3{
        width: 222px;
        max-width: 100%;
    }
    .w3-1{
        width: 240px;
    }
    .w4{
        width: 100px;
        max-width: 100%;
    }
    .w5{
        width: 60px;
    }

    .button.calculator{
        width: 185px;
        height: 50px;
    }

    .form-control.w4{
        padding: 12px 4px;
        text-align: center;
    }

    .input-group-label{
        width: 65px;
        height: 50px;
        padding: 0;
        background-color: $gray;
        .select2-container .select2-selection--single{
            background-color: $gray;
            border-left:0px;
            border-right:0px;
            padding: 12px 20px 12px 0px;
        }
        .select2-container--default .select2-selection--single .select2-selection__arrow{
            background-color: transparent;
            width: 22px;
        }
        .select2-container .select2-selection--single .select2-selection__rendered{
            font-weight: 500;
            padding-left: 0px;
            padding-right: 4px;
            line-height: 25px;
        }
    }
}

.calculator-parameters-container{
    .tabs-style-v2{
        margin-bottom: 0;
    }
}

.calculator-parameters{
    display: flex;
    margin-bottom: 40px;
    .parameters-image{
        flex: 0 0 30%;
        padding-top: 60px;
    }
    .parameters-content{
        flex: 0 0 70%;
        border-left: 1px solid $gray-200;
        padding-top: 60px;
    }

    .summary{
        display: flex;
        align-items: center;
        .button{
            margin: 0;
        }
        .summary-price{
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            background-color: $gray;
            height: 44px;
            margin-right: 3px;
            padding-left: 40px;

            .price-label{
                color: $secoundary;
                text-transform: uppercase;
                font-weight: 700;
                margin-right: 12px;
            }
            .price{
                font-size: 24px;
                color: $black;
                font-weight: 700;
            }
        }
    }
}

// calc resulsts
.hidden {
    display: none;
}

.image-search{
    text-align: center;
}

#error-message{
    text-align: left;
    color: $secoundary;
    padding-bottom: 12px;
    font-weight: 600;
    padding: 30px 0;
}

#result-search, #param-search{
    padding: 40px 0;
    td, th{
        border:1px solid $gray-200;
    }
    .h5{
        padding: 10px 0;
        margin: 0;
    }

    .price{
        font-size: 24px;
        color: #000;
        font-weight: 700;
    }

    .bord a{
        color :$dark;
        font-weight: 500;
        &:hover{
            color: $secoundary;
        }
    }

    .gr-tab{
        display: flex;
        justify-content: center;
        > div{
            margin: 10px 0;
        }
    }
    .tab1, .tab2, .tab3{
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        padding: 14px 20px;
    }
    
    .tab-active {
        background: $secoundary;
        color: #fff;
    }
    
    .tab-alt{
        color: $white;
        background-color: $dark;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        padding: 14px 20px;
        cursor: pointer;
    }
}

@include breakpoint(xlarge down) { 
    #result-search, #param-search{
        td, th{
            padding: 12px 20px
        }
    }
}

@include breakpoint(large down) { 
    .calculator-form{
        .w2{
            width: 230px;
        }
        .w3{
            width: 200px;
        }
        .label-empty{
            display: none;
        }
    }
}

@include breakpoint(medium down) { 
    .calculator-form {
        .w1, .w2, .w3{
            width: 100%;
        }
        .form-group{
            margin-right: 0;
            width: 100%;
            .input-group{
                margin-right: 10px;
            }
        }
        .input-group .button{
            width: 100%;
        }
        .label-empty{
            display: none;
        }
    }

    .grid-x-calculator-last{
        display: block;
    }
}

@include breakpoint(small down) { 
    .calculator-parameters{
        display: block;
        .parameters-content{
            border:0px;
        }

        .summary{
            display: block;
            .summary-price{
                padding: 4px 10px;
                justify-content: center;
            }
            .summary-buttons .button{
                width: 100%;
            }
        }
    }

    .calculator-form, #result-search, #param-search{
        padding: 0;
    }
}